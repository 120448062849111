import React from 'react'
import {i18n} from 'next-i18next'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import PageNotFound from './PageNotFound'

export async function getStaticProps({locale}) {
  if (process.env.NODE_ENV === 'development') {
    await i18n?.reloadResources()
  }
  return {
    props: {
      // Will be passed to the page component as props
      ...(await serverSideTranslations(locale, ['translation'])),
    },
  }
}

const Page = () => <PageNotFound />

export default Page
