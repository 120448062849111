import React from 'react'
import {localise} from '../../services/LocalizationServices'
import {ROOT_URL} from '../../constants/urls'
import Text from '../../components/text/Text'
import {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import {useRouter} from 'next/router'
import {Container, Image} from 'react-bootstrap'
import Card from '../../components/cards/Card'
import {GENERAL_ERROR_ICON} from '../../constants/images'

const PageNotFound = () => {
  const {replace} = useRouter()

  return (
    <Container className='my-5'>
      <Card className='pb-4'>
        <div className='p-4 d-flex flex-column align-items-center'>
          <Image width={216} height={144} src={GENERAL_ERROR_ICON} alt='error-icon' />
          <Text className='d-block' suppressLocalise variant={TEXT_TYPES.H1BoldPrimary}>404</Text>
          <Text className='d-block my-3' variant={TEXT_TYPES.H3RegularPrimary}>error.pageNotFound</Text>
        </div>
        <div className='text-center mt-3'>
          <PrimaryButton className='px-5 py-2' onClick={() => replace(ROOT_URL)}>
            {localise('buttonText.goBack')}
          </PrimaryButton>
        </div>
      </Card>
    </Container>
  )
}

export default PageNotFound
